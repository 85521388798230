<template>
  <div id="login">

  <section class="container mt-5">

    <div class="row justify-content-center">
    <Form @submit="onSubmit" :validation-schema="schema">

      <TextInput
        name="email"
        type="email"
        label="E-mail"
      />
      <TextInput
        name="password"
        type="password"
        label="Password"
      />

      <div class="row">
        <div class="col">
            <button type="submit" class="btn btn-custom" v-on:click="login">Login</button>
        </div>
        <div class="col">
            <button type="button" class="btn btn-custom" v-on:click="signup('/signup')">Create Account</button>
        </div>
      </div>

      <div class="text-center mt-4">            
        <router-link  v-bind:to="'/forgot-password'">Forgot Password</router-link>
      </div>


      <div class="login-social row d-block justify-content-center mt-5">
        
        <ul class="row d-flex justify-content-center ">
          <li class="list-group-item col-xs-12 col-sm-12 col-md-6 text-center">
            <!-- google	 -->
            <button v-on:click="loginWithGoogle" type="button" class="login-with-google-btn" >
              Sign in with Google
            </button>
          </li>
          <li class="list-group-item col-xs-12 col-sm-12 col-md-6 text-center">
            <button v-on:click="loginWithFacebook" type="button" class="login-with-facebook-btn" >
              Sign in with Facebook
            </button>
          </li>
        </ul>

      </div>

    </Form>

    </div>
  </section>

  <Footer/>
  </div>
</template>

<script>
import Api from '../scripts/api'
import Footer from '../components/global/Footer'

import { Form } from "vee-validate";
import * as Yup from "yup";
import TextInput from "../components/Form/TextInput";


export default {
    components: {
        Footer,
        Form,
        TextInput
    },
  setup() {
    var schema = Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string().required(),
      });
      return {
        schema,
      };
    },
    watch: {
      account: {
        immediate: true,
        handler (newAccount) {
          if(newAccount && newAccount.account) {
           this.$router.push('/');
         }
        }
      }
    },
    props: {
      account: Object
    },
    methods: {
      onSubmit: function(values) {
        this.login(values.email, values.password);
      },
      login: async function(email, password) {
        var result = await Api.login(email, password);
        if(result.status == 422) return this.$toast.error('Password or email is invalid.')
        if(result.status == 404) return this.$toast.error('Password or email did not match any of our records.');
        if(result.status == 403) return this.$toast.error('Password does not match.');
        if(result.status == 405) return this.$toast.error('You have been banned, please contact us.');
        if (result.status == 200) {
          this.$parent.$parent.setAccount(await result.json());
          this.$router.go('/');
        }
      },
      signup: function(url) {
        location.href=url;  
      },
      loginWithGoogle: async function() {
        var redirectionurl = 'https://competitionmania.co.uk/api/account/login/google';
        var clientid = '236739652833-99r6eq7opd4fpa1gt64o9o2h7of92ctt.apps.googleusercontent.com'
        var url = "https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20email%20profile&include_granted_scopes=true&redirect_uri=" + redirectionurl + "&response_type=code&client_id=" + clientid;  
        window.location.href = url;  
      },
      loginWithFacebook: async function() {
        window.location.href = "https://www.facebook.com/v10.0/dialog/oauth?scope=email&client_id=581414642797485&redirect_uri=https%3A%2F%2Fcompetitionmania.co.uk%2Fapi%2Faccount%2Flogin%2Ffacebook&state=1";
      }
    }
}
</script>

<style>

:root {
  --primary-color: #0071fe;
  --error-color: #f23648;
  --error-bg-color: #fddfe2;
  --success-color: #21a67a;
  --success-bg-color: #e0eee4;
}
.submit-btn {
  background: var(--primary-color);
  outline: none;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 10px 15px;
  display: block;
  width: 100%;
  border-radius: 7px;
  margin-top: 40px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.submit-btn:hover {
  transform: scale(1.1);
}
</style>
